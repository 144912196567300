import type { Job } from '@/types/graphql'

import { useState } from 'react'

import { StyledButton } from '@/components/ButtonWrapper'
import JobModal from '@/components/Workflow/JobModal'

type JobModalActionProps = {
  job: Job
  onChange?: (job: Job) => void
}

const JobModalAction = (props: JobModalActionProps) => {
  const { job, onChange } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleClick = () => setIsModalVisible(true)
  const handleClose = () => setIsModalVisible(false)

  return (
    <>
      <StyledButton style={{ marginLeft: '10px' }} onClick={handleClick}>
        View
      </StyledButton>
      <JobModal visible={isModalVisible} job={job} onClose={handleClose} onChange={onChange} />
    </>
  )
}

export default JobModalAction
