import { gql } from '@/types'

export const COUNTRIES_QUERY = gql(`
  query countriesForAddress1($query: String, $limit: Int) {
    countries(query: $query, limit: $limit) {
      rows {
        alpha3
      }
    }
  }
`)
