import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DatePicker, Form } from 'antd'
import { Button, Col, Input, Row } from 'antd-v5'
import moment from 'moment'
import styled from 'styled-components'

import EmptyAnchor from '@/components/EmptyAnchor'
import { ADD_LEG } from '@/graphql/leg'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  style: {
    marginBottom: 0
  }
}

const StyledAddLegDiv = styled.div`
  text-align: right;
  padding: 10px 0;
`

const StyledButtonDiv = styled.div`
  text-align: right;

  button {
    margin-left: 10px;
  }
`

const StyledFormDiv = styled.div`
  background-color: lightyellow;
  border-color: orange;
  border: 1px solid yellow;
  padding: 10px 0;
`

type NewLegContentProps = {
  form: WrappedFormUtils
  onChange?: () => void
  tripUuid: string
}

const NewLegContent = ({ form, onChange = () => {}, tripUuid }: NewLegContentProps) => {
  const [status, setStatus] = useState<'none' | 'adding' | 'loading'>('none')

  const handleAdd = () => setStatus('adding')
  const handleCancel = () => setStatus('none')

  const [addLeg] = useMutation(ADD_LEG)

  const handleSubmit = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setStatus('loading')

          await addLeg({ variables: { input: values } })
          onChange()

          form.resetFields()
          setStatus('none')
        } catch (error) {
          console.log(error)
          setStatus('adding')
        }
      }
    })
  }

  const { getFieldDecorator } = form

  return (
    <div>
      {status === 'none' && (
        <StyledAddLegDiv onClick={handleAdd}>
          <EmptyAnchor>Add a new leg</EmptyAnchor>
        </StyledAddLegDiv>
      )}
      {['adding', 'loading'].includes(status) && (
        <StyledFormDiv>
          <Form>
            {getFieldDecorator('tripUuid', {
              initialValue: tripUuid
            })(<Input type="hidden" />)}
            <Row>
              <Col span={12}>
                <Form.Item label="Start date" {...formItemLayout}>
                  {getFieldDecorator('start', {
                    initialValue: moment()
                  })(
                    <DatePicker
                      placeholder="Start"
                      showTime={{ format: 'h:mm:ss a', use12Hours: true }}
                      format="YYYY-MM-DD h:mm:ss a"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Type" {...formItemLayout}>
                  {getFieldDecorator('type', {
                    initialValue: ''
                  })(<Input placeholder="Type" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Driver ID" {...formItemLayout}>
                  {getFieldDecorator('driverId', {
                    initialValue: ''
                  })(<Input placeholder="Driver ID" />)}
                </Form.Item>
                <Form.Item label="PM ID" {...formItemLayout}>
                  {getFieldDecorator('pmId', {
                    initialValue: ''
                  })(<Input placeholder="PM ID" />)}
                </Form.Item>
                <Form.Item label="Trailer ID" {...formItemLayout}>
                  {getFieldDecorator('trailerId', {
                    initialValue: ''
                  })(<Input placeholder="Trailer ID" />)}
                </Form.Item>
                <Row>
                  <Col span={16} push={6}>
                    <StyledButtonDiv>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" loading={status === 'loading'} onClick={handleSubmit}>
                        Add
                      </Button>
                    </StyledButtonDiv>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </StyledFormDiv>
      )}
    </div>
  )
}

export default Form.create<NewLegContentProps>()(NewLegContent)
