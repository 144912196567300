import type { Booking, ProcessFlow } from '@/types/graphql'

import { Divider, Flex, Typography } from 'antd-v5'
import { find, upperCase } from 'lodash'

import { workflows } from '@/components/Workflow/Container'
import Section from '@/components/Workflow/Section'

type WorkflowProps = {
  process: Partial<ProcessFlow> | null
  data?: Partial<Booking> | null
  booking?: Partial<Booking> | null
  refetchBooking: () => void
  uploadActions: any
  requisitionActions: any
}

const Workflow = (props: WorkflowProps) => {
  const { process, requisitionActions, refetchBooking, booking } = props

  const mappedWorkflow = workflows[process?.type ?? ''] || {
    type: process?.type,
    text: process?.type
  }
  const hasDisplayableSection = find(process?.sections, section => section?.display === true)

  if (!mappedWorkflow || !hasDisplayableSection) return null

  return (
    <Flex id={process?.type || ''} vertical gap={5}>
      <Divider plain>
        <Typography.Title level={5} style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
          {upperCase(mappedWorkflow.text)}
        </Typography.Title>
      </Divider>

      {process?.sections?.map(section => {
        if (section?.display) {
          return (
            <Section
              refetchBooking={refetchBooking}
              requisitionActions={requisitionActions}
              key={section?.name}
              section={section}
              booking={booking}
              process={process}
              extras={mappedWorkflow.extras?.[section?.type]}
            />
          )
        } else {
          return null
        }
      })}
    </Flex>
  )
}

export default Workflow
