import { gql } from '@/types'

export const ADD_LEG = gql(`
  mutation addLegAss($input: AddLegInput!) {
    addLeg(input: $input) {
      success
      message
    }
  }
`)
